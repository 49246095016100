import styled from "styled-components";
import {Link} from "gatsby";

const Container = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: ${ props => props.long ? 'column' : 'row' };
    flex-wrap: wrap;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    @media (max-width: 769px) {
      padding-bottom: 95px;
    }
`;

const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    width: 80%;
    max-width: 1250px;
    @media (max-width: 1160px) {
        width: 100%;
      }
    @media (max-width: 769px) {
      flex-direction: column;
      align-items: center;

    }
`;

const Bar = styled.h1`
    text-align: center;
    background-color: #202620;
    color: #fff;
    padding: 25px;
`;

const List = styled.ul`
  text-decoration: none;
  display: flex;
  justify-content: flex-start;
  flex-flow: row wrap;
  list-style: none;
  margin-left: 0px;
  width: 75%;
  height: 100%;
  @media (max-width: 769px) {
    width: 95%;
    padding-bottom: 95px;
  }
`;

const Item = styled(Link)`
  display: flex;
  flex-direction: column;
  width: 22.5%;
  margin: 1.25%;
  margin-top: 0px;
  cursor: pointer;
  color: #000;
  text-decoration: none;
  hieght: fit-content;
  @media (max-width: 769px) {
    width: 45%;
    margin: 2.5%;
    margin-bottom: 7%;
  }
`;

const Header = styled.div`
  font-size: 14px;
  background-color: #fff;
  color: #000;
  width: 100%;
  font-weight: bold;
  text-align: center;
`;

const Picture = styled.img`
  margin: 0px;
  height: 175px;
  object-fit: cover;
`;

const Quant = styled.div`
  font-size: 10px;
  color: #1E73BE;
  text-align: center;
  margin: -5px;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: left;
  margin: 10px;
  margin-left: 0px;
`;

const Title = styled.div`
  font-size: 22px;
  font-weight: 700;
  margin: 10px;
  @media (max-width: 769px) {
    font-size: 20px
  }
`;

const Para = styled.div`
  font-size: 16px;
  margin: 10px;
`;

export {
    Container,
    List,
    Item,
    Header,
    Picture,
    Quant,
    Bar,
    Info,
    Title,
    Para,
    Wrapper
}