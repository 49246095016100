import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import {Container, Bar, Wrapper} from "../styles/inventoryStyles"
import Sidemenu from "../components/sideMenu"
import InventoryGrid from "../components/inventory"

const Inventory = () => (
  <Layout>
    <SEO 
    title="Current Inventory" 
    description="At SR Affordable Equipment, we pride ourselves on providing a quality buying/selling service to all of our customers. We understand how valuable your time is, so we work hard and take the hassle out of selling or purchasing heavy construction equipment so you don’t have to.  Whether you need a Used Excavator, Used Dozer, Used Backhoe or any other type of heavy equipment, let SR Affordable Equipment LLC make the process hassle-free! SR Affordable Equipment is also an authorized dealer for Terex Environmental, Generac and Topcon! If you can’t find the piece of equipment you’re looking for on our website, we find it for you and do all the negotiating so you get more for your dollar!"
    keywords={["Used Heavy Equipment Sales", "SR Affordale Equipment", "Construction Equipment", "Heavy Equipment Rentals", "Heavy Equipment for Sale", "Service and Repair", "Aggregate Equipment", "Attachments", "Compactors", "Dozers", "Excavators", "Loader Backhoes", "Motor Graders", "Motor Scrapers", "On-Road Trucks", "Off-Highway Trucks", "Skid Steers", "Skip Loaders", "Wheel Loaders", process.env.GATSBY_PHONE_NUMBER]}
    />
    <Bar>USED EQUIPMENT FOR SALE</Bar>
    <Container>
      <Wrapper>
        <InventoryGrid/>
        <Sidemenu/>
      </Wrapper>
    </Container>
  </Layout>
)



export default Inventory
